import React from "react"
import { graphql, Link } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import Layout from "../components/layout"
import { formatDate, capitalize } from "../utils/index"

export const pageQuery = graphql`
  query ContentfulBlogPostBySlug($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      id
      date
      slug
      title
      author {
        id
        fullName
        picture {
          fluid {
            src
          }
        }
      }
      featuredImage {
        fluid {
          src
        }
      }
      content {
        json
      }
    }
  }
`

const BlogPostContentfulTemplate = ({ data }) => {
  return (
    <Layout>
      <main className="container">
        <div className="section">
          <Link className="is-block mb-3" to="/news">
            Back
          </Link>
          <h1 className="title is-h1">{data.contentfulBlogPost.title}</h1>
          <div className="media">
            <div className="media-left">
              <figure className="image is-48x48">
                <img
                  className="is-rounded full-height cover"
                  src={data.contentfulBlogPost.author.picture.fluid.src}
                  alt="author"
                />
              </figure>
            </div>
            <div className="media-content">
              <p>{capitalize(data.contentfulBlogPost.author.fullName)}</p>
              <p className="tag is-info is-light">
                {formatDate(data.contentfulBlogPost.date)}
              </p>
            </div>
          </div>
          <div className="mt-4">
            <figure className="image is-16by9">
              <img
                src={data.contentfulBlogPost.featuredImage.fluid.src}
                alt=""
              />
            </figure>
          </div>
        </div>
        <div className="section content">
          {documentToReactComponents(data.contentfulBlogPost.content.json)}
        </div>
      </main>
    </Layout>
  )
}

export default BlogPostContentfulTemplate
